import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';
import TAB_ASSIGNMENTS from './tab_assignments/tab_assignments'
import TAB_PREQUALIFICATIONS from './tab_prequalifications/tab_prequalifications'

export default function REQUIREMENT_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_requirement, set_requirement ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
        populate_requirement_details();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.requirementid]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirement_details() {
        try {
            let results = await API_get_requirement_details();
            set_requirement(results);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirement_details() {
        return API.get('requirements2', '/get-org-requirement/' + params.requirementid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/requirements2', text: t('Requirements') }]}
                current_page_text={var_requirement.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_requirement.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Requirement')} id='tabs_requirement_detail'>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='PREQUALIFICATIONS' label={t('Pre-qualifications')} />
                    <TABS.TAB name='INDIVIDUALS' label={t('Individuals')} />
                    <TABS.TAB name='ASSIGNMENTS' label={t('Assignments')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS requirement={var_requirement} onChange_requirement={populate_requirement_details} />
                }

                {var_tab === 'INDIVIDUALS' &&
                    <TAB_INDIVIDUALS requirement_id={params.requirementid} />
                }

                {var_tab === 'ASSIGNMENTS' &&
                    <TAB_ASSIGNMENTS requirement_id={params.requirementid} />
                }

                {var_tab === 'PREQUALIFICATIONS' &&
                    <TAB_PREQUALIFICATIONS requirement_id={params.requirementid} />
                }

            </div>
        </div>
    )
};