import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import { FORM_DATEPICKER, FORM_INPUT, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';


export default function CMP_CREDIT_ACTIVITY({ is_open, holder_name, onClose, onChange, activity_id, can_edit = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credit_activity_details, set_credit_activity_details  ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            // reset
            set_modal(document.querySelector('#mdl_activity_details'));
            set_processing(false);
            set_save_error(null);
            set_credit_activity_details({});
            set_errors([]);
            populate_details();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_details() {
        set_processing(true);
        try {
            let results = await API_get_credential_credit_activity();
            if (results.activity_date) {
                results.activity_date = datelib.epoch_to_utcmoment(results.activity_date)._d;
            }
            set_credit_activity_details(results);
        } catch (e) {
            console.log(e);
            set_save_error(t('An error has occurred'));
        }
        set_processing(false);
    }

    async function save_details(credit_activity_details) {
        if (var_processing) return;
        set_processing(true);
        try {
            await API_put_credential_credit_activity(credit_activity_details);
            onChange();
        } catch (e) {
            console.log(e);
            set_save_error(t('An error has occurred'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_activity() {
        return API.get('credentials', '/get-credential-credit-activity/' + activity_id);
    }

    function API_put_credential_credit_activity(credit_activity_details) {
        return API.put('credentials', '/put-credential-credit-activity/' + activity_id,
            {
                queryStringParameters: { tz: datelib.timezone },
                body: credit_activity_details
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_save() {
        let errors = [];
        let credit_activity_details = { ...var_credit_activity_details };
        if (!form_helper.validate_positive_number(credit_activity_details.credit_value)) {
            errors.push([{ property: 'credit_value', description: t('You must insert a credit value') }]);
        }
        if (!credit_activity_details.activity_date) {
            errors.push([{ property: 'activity_date', description: t('You must select a date') }]);
        }
        set_errors(errors);

        if (errors.length > 0) return;

        credit_activity_details.activity_date = datelib.date_to_epoch(credit_activity_details.activity_date);

        save_details(credit_activity_details);
    }

    function onBlur_credit_value() {
        let updated_activity_details = { ...var_credit_activity_details };
        // Make value a string so we can pad it
        updated_activity_details.credit_value = String(updated_activity_details.credit_value);
        if (updated_activity_details.credit_value > 0) {
            updated_activity_details.credit_value = form_helper.pad_decimal_string(updated_activity_details.credit_value, 2);
        }
        set_credit_activity_details(updated_activity_details);
    }

    function onChange_credit_activity(value, name) {
        set_errors([]);
        let updated_activity_details = { ...var_credit_activity_details };
        if (name === 'credit_value') {
            value = form_helper.format_decimal_string(value, 4, 2);
        }
        updated_activity_details[name] = value;
        set_credit_activity_details(updated_activity_details);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_activity_details'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_activity_details'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_activity_details'>{holder_name}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_activity_details' aria-labelledby='hdr_activity_details' tabIndex='0'>

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                {var_errors.length > 0 &&
                    <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={t('There are some errors with your inputs')}
                    />
                }

                <FORM_DATEPICKER
                    property='activity_date'
                    label={t('Date')}
                    value={var_credit_activity_details.activity_date  || ''}
                    onChange={(_e, { value }) => onChange_credit_activity(value, 'activity_date')}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    minproperty={null}
                    maxproperty={null}
                    errors={var_errors}
                    disabled={!can_edit}
                />
                <FORM_INPUT
                    label={t('Holder')}
                    property='holder'
                    value={holder_name}
                    errors={var_errors}
                    disabled
                />

                {var_credit_activity_details.parent_credential_credit_description &&
                    <FORM_TEXTAREA
                        label={t('Category')}
                        property='credential_credit_description'
                        value={var_credit_activity_details.parent_credential_credit_description}
                        errors={var_errors}
                        disabled
                    />
                }

                <FORM_TEXTAREA
                    label={t('Task')}
                    property='credential_credit_description'
                    value={var_credit_activity_details.credential_credit_description}
                    errors={var_errors}
                    disabled
                />

                <div className='item_credits'>
                    <FORM_INPUT
                        property='credit_value'
                        placeholder={t('Credits')}
                        label={t('Credits completed')}
                        value={var_credit_activity_details.credit_value ? String(var_credit_activity_details.credit_value) : ''}
                        onChange={(_e, { value }) => onChange_credit_activity(value, 'credit_value')}
                        onBlur={onBlur_credit_value}
                        errors={var_errors}
                        disabled={!can_edit}
                    />
                </div>

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {can_edit && <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>}
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}
